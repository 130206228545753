import React from 'react'
import { Checkbox } from 'shared-components'
import RegisterContent from '../../../content/register'
import { UserInformationObject } from '../Register'

const { termsAcceptForm } = RegisterContent

export type TermsSelection = {
  termsAccepted: boolean
  codeOfConductAccepted: boolean
  newsletterSubscribed: boolean
}

const TermsAcceptForm = ({
  goToPreviousStep,
  registerUser,
  updateSelection,
  userInformation,
}: {
  goToPreviousStep: () => void
  updateSelection: (selectedTerms: TermsSelection) => void
  registerUser: () => void
  userInformation: UserInformationObject
}) => {
  return (
    <form
      name="TermsAcceptForm"
      onSubmit={(e) => {
        e.preventDefault()
        registerUser()
      }}
      data-testid="terms-accept-form"
      autoComplete="off"
    >
      <div className="mb-4">
        <Checkbox
          testId="code-of-conduct-checkbox"
          isChecked={userInformation.codeOfConductAccepted}
          fieldName={'Code Of Conduct'}
          label={termsAcceptForm.codeOfConduct}
          onChange={(value: boolean) =>
            updateSelection({
              ...userInformation,
              codeOfConductAccepted: value,
            })
          }
        />
      </div>
      <div className="mb-4">
        <Checkbox
          testId="terms-checkbox"
          isChecked={userInformation.termsAccepted}
          fieldName={'Terms'}
          label={termsAcceptForm.terms}
          onChange={(value: boolean) =>
            updateSelection({
              ...userInformation,
              termsAccepted: value,
            })
          }
        />
      </div>
      <div className="mb-4">
        <Checkbox
          testId="newsletter-checkbox"
          isChecked={userInformation.newsletterSubscribed}
          fieldName={'Newsletter Subscribe'}
          label={termsAcceptForm.newsletterSubscribe}
          onChange={(value: boolean) =>
            updateSelection({
              ...userInformation,
              newsletterSubscribed: value,
            })
          }
        />
      </div>
      <p className="text-center text-gray-2 text-xs mb-2">*Required</p>
      <button
        type="submit"
        className="Button__Navy--primary navigationButton"
        disabled={
          !userInformation.codeOfConductAccepted ||
          !userInformation.termsAccepted
        }
      >
        {termsAcceptForm.registerButtonLabel}
      </button>
      <button
        type="button"
        onClick={() => goToPreviousStep()}
        className="Button__Grey navigationButton"
      >
        {termsAcceptForm.backButtonLabel}
      </button>
    </form>
  )
}

export default TermsAcceptForm
