import React, { useState, useEffect } from 'react'
import { Tooltip } from 'react-tooltip'
import { useAuth0 } from '@auth0/auth0-react'
import RegisterContent from '../../../content/register'
import { gql, useLazyQuery } from '@apollo/client'

export const GET_PROFILE_DETAILS = gql(`
    query getProfile($userId: UserID!){
        profile(userId: $userId) {
            handle
        }
    }
`)

const { userDetailsForm } = RegisterContent

const UserDetailsForm = ({
  referredBy,
  onUsernameUpdate,
  goToNextStep,
  closeRegistration,
  usernameCharactersValid,
}: {
  referredBy: string
  onUsernameUpdate: (referredBy: string) => void
  goToNextStep: () => void
  closeRegistration: () => void
  usernameCharactersValid: boolean
}) => {
  const [userId, setUserId] = useState('')
  const [handle, setHandle] = useState('')
  const [loadingHandle, setLoadingHandle] = useState(true)
  const { user } = useAuth0()

  const [
    getUserProfile,
    { data: userProfileResponse, loading: userProfileLoading },
  ] = useLazyQuery(GET_PROFILE_DETAILS, {
    variables: { userId: userId },
  })

  useEffect(() => {
    if (user && user.sub) {
      setUserId(user.sub)
    }
  }, [user])

  useEffect(() => {
    if (userId) {
      getUserProfile()
    }
  }, [userId])

  useEffect(() => {
    setLoadingHandle(userProfileLoading ? true : false)
  }, [userProfileLoading])

  useEffect(() => {
    if (
      userProfileResponse &&
      userProfileResponse.profile &&
      userProfileResponse.profile.handle
    ) {
      setHandle(userProfileResponse.profile.handle)
    }
  }, [userProfileResponse])

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        goToNextStep()
      }}
      className="UserDetailsForm"
      data-testid="user-details-form"
      autoComplete="off"
    >
      <label htmlFor="username-input">Username</label>
      <input
        type="text"
        id="username-input"
        value={
          handle || (loadingHandle ? 'loading...' : 'Username not available')
        }
        disabled
        name="username"
        readOnly
      />
      <label htmlFor="email-input">Email</label>
      <input
        type="text"
        id="email-input"
        value={user?.email || 'Email not available'}
        disabled
        name="email"
        readOnly
      />
      <p className="text-xs text-gray-2 my-6 text-center font-sora">
        {userDetailsForm.helpText}
      </p>
      <p className="text-sm text-qhack-2024-navy-1 mt-6 mb-4 text-center font-sora">
        {userDetailsForm.referText}
      </p>
      <input
        type="text"
        id="referrer-username-input"
        placeholder="username"
        value={referredBy}
        onChange={(e) => onUsernameUpdate(e.target.value || '')}
        name="refer-username"
        minLength={3}
        maxLength={20}
        required={!!referredBy.length}
      />
      <p className="text-gray-2 mt-2">
        <i
          id="refer"
          className="bx bxs-info-circle  cursor-pointer text-lg"
          aria-label={userDetailsForm.tooltipText}
          tabIndex={0}
        ></i>
        <span className="text-xs ml-1 align-text-top">
          {userDetailsForm.tooltipLabel}
        </span>
      </p>
      <button
        type="submit"
        disabled={!usernameCharactersValid}
        className="Button__Navy--primary navigationButton"
      >
        {userDetailsForm.nextButtonLabel}
      </button>
      <button
        type="button"
        onClick={() => closeRegistration()}
        className="Button__Grey navigationButton"
      >
        {userDetailsForm.cancelButtonLabel}
      </button>
      <Tooltip anchorSelect="#refer" aria-haspopup="true" place="bottom-start">
        <p className="text-white text-xs w-[250px] leading-5">
          {userDetailsForm.tooltipText}
        </p>
      </Tooltip>
    </form>
  )
}

export default UserDetailsForm
