import React from 'react'
import { Script } from 'gatsby'
import { hotjarScript } from './ThirdPartyScripts'
import { getCurrentEnv } from '../../../config'

const ScriptProvider = ({ children }: { children: JSX.Element }) => {
  return (
    <>
      {children}
      {getCurrentEnv() === 'PROD' && (
        <>
          <Script
            key="hotjar-qhack"
            dangerouslySetInnerHTML={{
              __html: hotjarScript,
            }}
          />
        </>
      )}
    </>
  )
}

export default ScriptProvider
