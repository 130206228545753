module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-Z9HT4JQQB3"],"pluginConfig":{"head":true,"exclude":["dev.qhack.ai/**"],"respectDNT":false,"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"gtagConfig":{}},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PH678DX6","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"QHack 2024 — Quantum computing hackathon","short_name":"QHack 2024","start_url":"/","background_color":"#312c72","theme_color":"#312c72","display":"standalone","icon":"static/favicon/favicon.png","icons":[{"src":"/favicon/favicon-16x16.png","sizes":"16x16","type":"image/png"},{"src":"/favicon/favicon-32x32.png","sizes":"31x32","type":"image/png"},{"src":"/favicon/apple-touch-icon.png","sizes":"180x180","type":"image/png"},{"src":"/favicon/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/favicon/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"335bf786b560ff3a7f0a41d8df7aa8fd"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
