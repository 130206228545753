import React, { MouseEventHandler, ReactNode } from 'react'
import { Link } from 'gatsby'

export interface IScrollLinkProps
  extends React.HTMLAttributes<HTMLAnchorElement> {
  children: ReactNode
  to: string
  offset?: number
  id?: string
  addClass?: string
  tabIndex?: number
  testId?: string
  onClick?: MouseEventHandler<HTMLAnchorElement>
}

const ScrollLink = ({
  children,
  to,
  offset = 80, // Default offset is 80px to account for the navbar + extra padding
  id = '',
  addClass = '',
  tabIndex = 0,
  testId = '',
  onClick,
  ...props
}: IScrollLinkProps) => {
  const windowPresent = typeof window !== 'undefined'
  const isHash = to.startsWith('#')
  return (
    <Link
      id={id}
      className={`ScrollLink ${addClass}`}
      tabIndex={tabIndex}
      to={to}
      onClick={(e) => {
        if (windowPresent && isHash) {
          e.preventDefault()
          const el = document.querySelector(to)
          if (el) {
            const rect = el.getBoundingClientRect()
            const scrollTop =
              window.scrollY || document.documentElement.scrollTop
            const top = rect.top + scrollTop - offset
            window.scrollTo({ top, behavior: 'smooth' })
            window.history.pushState({}, '', to)
          } else {
            window.location.href = to
          }
        }
        // Default is not prevented if window is undefined
        onClick?.(e)
      }}
      data-testid={testId ? testId : 'internal-link'}
      {...props}
    >
      {children}
    </Link>
  )
}

export default ScrollLink
