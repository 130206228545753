import React, { HTMLAttributes } from 'react'
import ScrollLink from '../ScrollLink/ScrollLink'
import { getCurrentEnvConfig } from '../../../config'
import { Link } from 'gatsby'

interface ILinkComponentProps extends HTMLAttributes<HTMLAnchorElement> {
  children?: React.ReactNode
  href: string
  addClass?: string
  id?: string
  tabIndex?: number
}
const SKIP_INTERNAL_ROUTING_FRAGMENTS = [
  '/dashboard',
  '/privacy-policy',
  '/terms-and-conditions',
  '/code-of-conduct',
  '/accessibility',
]

const SKIP_NO_REFERRER_DOMAINS = ['pennylane.ai', 'xanadu.ai', 'qhack.ai']

const skipNoReferrer = (url: string) => {
  return !!SKIP_NO_REFERRER_DOMAINS.find((domain) => url.includes(domain))
}

export default function LinkComponent({
  children,
  href,
  addClass = '',
  id = '',
  tabIndex = 0,
  ...props
}: ILinkComponentProps) {
  const skipInternalRouting = SKIP_INTERNAL_ROUTING_FRAGMENTS.some(
    (URLFragment) => href.includes(URLFragment)
  )
  const isInternalURL = href.startsWith('/')
  const isDashboardURL = href.includes('/dashboard')

  if (isInternalURL && !skipInternalRouting) {
    return (
      <Link
        tabIndex={tabIndex}
        to={href}
        className={addClass}
        data-testid="internal-link"
        id={id}
        {...props}
      >
        {children}
      </Link>
    )
  }

  const onPage = href.startsWith('#')
  const offset = href === '#coding-challenges' ? 110 : undefined

  if (onPage) {
    return (
      <ScrollLink to={href} addClass={addClass} offset={offset} {...props}>
        {children}
      </ScrollLink>
    )
  }

  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a
      tabIndex={tabIndex}
      href={
        isDashboardURL
          ? `${getCurrentEnvConfig().dashboardOrigin}${href}`
          : href
      }
      // Open the link in a new page
      target={isInternalURL ? '_self' : '_blank'}
      // Add noopener and noreferrer for security reasons
      rel={
        isInternalURL
          ? ''
          : `noopener ${skipNoReferrer(href) ? '' : 'noreferrer'}`
      }
      className={addClass}
      data-testid="external-link"
      id={id}
      {...props}
    >
      {children}
    </a>
  )
}
