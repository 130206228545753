import './src/styles/global.css'
import React from 'react'
import Layout from './src/components/Layout/Layout'
import ApolloClientChallengeSystem from './src/providers/ApolloProvider/ApolloClientChallengeSystem'
import AuthProvider from './src/providers/AuthProvider/AuthProvider'
import ScriptProvider from './src/providers/ScriptProvider/ScriptProvider'
import AppContextProvider from './src/providers/AppContext/AppContext'

const wrapRootElement = ({ element }) => {
  return (
    <AuthProvider>
      <ApolloClientChallengeSystem>
        <AppContextProvider>{element}</AppContextProvider>
      </ApolloClientChallengeSystem>
    </AuthProvider>
  )
}

const wrapPageElement = ({ element, props }) => {
  return (
    <ScriptProvider>
      <Layout {...props}>{element}</Layout>
    </ScriptProvider>
  )
}

const shouldUpdateScroll = ({ routerProps: { location } }) => {
  const hasHash = location.hash !== ''
  if (typeof window !== undefined && !hasHash)
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'instant',
    })
  return true
}

// Export browser configurations
export { wrapRootElement, wrapPageElement, shouldUpdateScroll }
