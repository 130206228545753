import React from 'react'
import { useState, useEffect, useContext } from 'react'
import Modal from 'react-modal'
import UserDetailsForm from './RegisterForms/UserDetailsForm'
import OptionalDetailsForm from './RegisterForms/OptionalDetailsForm'
import TermsAcceptForm from './RegisterForms/TermsAcceptForm'
import RegisterContent from '../../content/register'
import { OptionType } from './RegisterForms/OptionalDetailsForm'
import { TermsSelection } from './RegisterForms/TermsAcceptForm'
import { get } from 'lodash'
import { gql, useLazyQuery, useMutation } from '@apollo/client'
import { subscribeToMailchimpList } from 'shared-utilities'
import { getCurrentEnvConfig } from '../../../config'
import './Register.css'
import { useAuth0 } from '@auth0/auth0-react'
import LinkComponent from '../LinkComponent/LinkComponent'
import { IOption } from './RegisterForms/OptionalDetailsForm'
import ApolloClientProfileUpdate from '../../providers/ApolloProvider/ApolloClientProfileUpdate'
import { AppContext } from '../../providers/AppContext/AppContext'

const config = getCurrentEnvConfig()

// Allow only alphanumeric and underscore characters
const USERNAME_REGEX = /^[a-zA-Z0-9_]+$/

export const GET_PLAYER_DETAILS = gql(`
  query GetPlayerCurrent {
    player {
      id
    }
  }
`)

export const GET_EVENT_DETAILS = gql(`
  query GetEventDetails {
    events {
      id
      slug
      status
      registrationOpen
      teamsLocked
    }
  }
`)

export const CREATE_PLAYER = gql(`
  # Registers new users
  mutation PlayerCreate($meta: JSON!) {
    playerCreate(meta: $meta) {
      player {
        id
      }
      userErrors {
        field
        message
      }
    }
  }
`)

export type Event = {
  id: string
  registrationOpen: boolean
  slug: string
  status: string
  teamsLocked: boolean
}

type SubmitError = {
  field?: string
  message: string
}

export type UserInformationObject = {
  referredBy: string
  country: string
  education: string
  experience: string
  referralSource: IOption[]
  framework: IOption[]
  attendQHackBefore: string
  termsAccepted: boolean
  codeOfConductAccepted: boolean
  newsletterSubscribed: boolean
}

const { title, registrationSuccess, registeredUserCTA, registerButtonLabel } =
  RegisterContent
const { invalidUsernameError } = RegisterContent.userDetailsForm

const Register = ({
  showOnlyCTAWithoutModal = false,
}: {
  showOnlyCTAWithoutModal?: boolean
}) => {
  const { user, isAuthenticated, loginWithRedirect, isLoading } = useAuth0()
  const {
    eventDetails,
    setEventDetails,
    playerDetails,
    setPlayerDetails,
    showRegisterModal,
    setShowRegisterModal,
  } = useContext(AppContext)

  const [currentStep, setCurrentStep] = useState(0)
  const [usernameCharactersValid, setUsernameCharactersValid] = useState(true)
  const [isQueryError, setIsQueryError] = useState(false)

  const [userInformation, setUserInformation] = useState<UserInformationObject>(
    {
      referredBy: '',
      country: '',
      education: '',
      experience: '',
      referralSource: [],
      framework: [],
      attendQHackBefore: '',
      termsAccepted: false,
      codeOfConductAccepted: false,
      newsletterSubscribed: false,
    }
  )
  const [submitErrors, setSubmitErrors] = useState<SubmitError[] | null>(null)

  const [getEventDetails, { data, error }] = useLazyQuery(GET_EVENT_DETAILS)

  const [
    getPlayerData,
    { data: playerData, error: playerError, loading: PlayerDataLoading },
  ] = useLazyQuery(GET_PLAYER_DETAILS, {
    context: {
      headers: {
        'X-Event-ID': eventDetails.id,
      },
    },
  })

  const [createPlayer] = useMutation(CREATE_PLAYER, {
    context: {
      headers: {
        'X-Event-ID': eventDetails.id,
      },
    },
  })

  useEffect(() => {
    if (!eventDetails.id) {
      getEventDetails()
    }
  }, [])

  useEffect(() => {
    if (data && data.events && data.events.length) {
      data.events.forEach((event: Event) => {
        if (event.slug === config.activeEventSlug) setEventDetails(event)
      })
    }
    if (error) {
      setIsQueryError(true)
    }
  }, [data, error])

  useEffect(() => {
    if (playerData && playerData.player && playerData.player.id) {
      setPlayerDetails({
        ...playerDetails,
        id: playerData.player.id || '',
      })
    }
    if (playerError) {
      setIsQueryError(true)
    }
  }, [playerData, playerError])

  useEffect(() => {
    if (
      !!eventDetails.id &&
      !playerDetails.id &&
      !isLoading &&
      isAuthenticated
    ) {
      if (!PlayerDataLoading) {
        getPlayerData()
      }
    }
  }, [eventDetails.id, playerDetails.id, isLoading, isAuthenticated])

  // Update URL to support analytics tracking
  useEffect(() => {
    if (typeof window !== 'undefined' && 'URLSearchParams' in window) {
      const url = new URL(window.location.href)
      if (showRegisterModal) {
        if (currentStep === 0) url.searchParams.set('register', 'refer')
        if (currentStep === 1)
          url.searchParams.set('register', 'optional-details')
        if (currentStep === 2) url.searchParams.set('register', 'accept-terms')
      } else {
        url.searchParams.delete('register')
      }
      history.pushState({}, '', url.href)
    }
    // reset errors on step change
    setSubmitErrors(null)
  }, [currentStep, showRegisterModal])

  const registerUserForEvent = async () => {
    setSubmitErrors(null)
    if (eventDetails.registrationOpen && eventDetails.status !== 'FINISHED') {
      try {
        const response = await createPlayer({
          variables: {
            meta: userInformation,
          },
        })
        setPlayerDetails({
          ...get(response, 'data.playerCreate.player', {}),
        })
        if (
          userInformation.newsletterSubscribed &&
          config.newsletterMailchimp.baseURL &&
          config.newsletterMailchimp.mailingList
        ) {
          subscribeToMailchimpList(
            { EMAIL: user?.email },
            config.newsletterMailchimp.baseURL,
            config.newsletterMailchimp.mailingList
          )
        }
        if (
          config.welcomeEmailMailchimp.baseURL &&
          config.welcomeEmailMailchimp.mailingList
        ) {
          subscribeToMailchimpList(
            { EMAIL: user?.email },
            config.welcomeEmailMailchimp.baseURL,
            config.welcomeEmailMailchimp.mailingList
          )
        }
        const userErrors = get(response, 'data.playerCreate.userErrors')
        if (userErrors && userErrors.length) setSubmitErrors(userErrors)
        else {
          setCurrentStep(3)
        }
      } catch (error) {
        setSubmitErrors([
          {
            message:
              'There was an error on the server, please try again later.',
          },
        ])
      }
    }
  }

  return (
    <>
      {playerDetails.id ? (
        <LinkComponent
          href={
            eventDetails.teamsLocked
              ? registeredUserCTA.eventNotStarted.link
              : registeredUserCTA.eventStarted.link
          }
          addClass="RegisterButton Hero__links__left Button__primary"
        >
          {eventDetails.teamsLocked
            ? registeredUserCTA.eventNotStarted.label
            : registeredUserCTA.eventStarted.label}
        </LinkComponent>
      ) : isAuthenticated || isLoading ? (
        <button
          onClick={() =>
            eventDetails.registrationOpen && setShowRegisterModal(true)
          }
          className="RegisterButton Hero__links__left Button__primary"
          disabled={!eventDetails.registrationOpen || PlayerDataLoading}
          data-testid="signed-in-non-registered-user-button"
        >
          {registerButtonLabel.loggedIn}
        </button>
      ) : (
        <button
          className="Hero__links__left Button__primary"
          onClick={() => {
            loginWithRedirect()
          }}
          data-testid="signed-out-non-registered-user-button"
        >
          {registerButtonLabel.loggedOut}
        </button>
      )}
      {showOnlyCTAWithoutModal ? null : (
        <Modal
          isOpen={showRegisterModal}
          onRequestClose={() => {
            setShowRegisterModal(false)
          }}
          shouldCloseOnEsc={false}
          shouldCloseOnOverlayClick={false}
          className="Register__modal"
          id="register-modal"
          ariaHideApp={false}
          testId="register-modal"
          aria={{
            labelledby: 'register-modal-title',
          }}
        >
          <button
            className="Register__modal__close"
            aria-label="close registration modal"
            onClick={() => setShowRegisterModal(false)}
          >
            <i className="bx bx-x"></i>
          </button>
          {currentStep === 3 ? (
            <h2 id="register-modal-title">{registrationSuccess.title}</h2>
          ) : (
            <h2 id="register-modal-title">{title}</h2>
          )}
          {currentStep === 0 && (
            <ApolloClientProfileUpdate>
              <UserDetailsForm
                referredBy={userInformation.referredBy}
                usernameCharactersValid={usernameCharactersValid}
                onUsernameUpdate={(referredBy: string) => {
                  setUserInformation({ ...userInformation, referredBy })
                  if (referredBy) {
                    const usernameCharactersValid = new RegExp(
                      USERNAME_REGEX
                    ).test(referredBy)
                    setUsernameCharactersValid(usernameCharactersValid)
                    if (!usernameCharactersValid) {
                      setSubmitErrors([{ message: invalidUsernameError }])
                    } else {
                      setSubmitErrors(null)
                    }
                  } else {
                    setSubmitErrors(null)
                    setUsernameCharactersValid(true)
                  }
                }}
                goToNextStep={() => setCurrentStep(1)}
                closeRegistration={() => {
                  setShowRegisterModal(false)
                }}
              />
            </ApolloClientProfileUpdate>
          )}
          {currentStep === 1 && (
            <OptionalDetailsForm
              userInformation={userInformation}
              goToPreviousStep={() => setCurrentStep(0)}
              onUpdateAnswers={(optionalAnswers: OptionType) => {
                setUserInformation({ ...userInformation, ...optionalAnswers })
                setCurrentStep(2)
              }}
            />
          )}
          {currentStep === 2 && (
            <TermsAcceptForm
              userInformation={userInformation}
              goToPreviousStep={() => setCurrentStep(1)}
              updateSelection={(selectedTerms: TermsSelection) =>
                setUserInformation({ ...userInformation, ...selectedTerms })
              }
              registerUser={() => {
                registerUserForEvent()
              }}
            />
          )}
          {currentStep === 3 && (
            <button
              onClick={() => setShowRegisterModal(false)}
              className="Button__Navy--primary navigationButton"
            >
              {registrationSuccess.buttonLabel}
            </button>
          )}
          {submitErrors && submitErrors.length && (
            <div
              className="mb-4 mt-8 text-sm"
              data-testid="register-form-errors"
            >
              <p className="text-error-1 font-semibold mb-2">
                The following error(s) occurred:
              </p>
              <ul className="list-disc pl-4">
                {submitErrors.map((error, index) => (
                  <li className="text-error-1" key={`error-${index}`}>
                    {error.message}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </Modal>
      )}
    </>
  )
}

export default Register
