import React, { useState, useEffect } from 'react'
import { Select } from 'shared-components'
import countries from 'i18n-iso-countries'
import english from 'i18n-iso-countries/langs/en.json'
import RegisterContent from '../../../content/register'
import { UserInformationObject } from '../Register'

export interface IOption {
  label: string
  value: string
}

export type OptionType = {
  [key: string]: string | IOption[]
}

const FORM_NAME = 'OptionalRegistrationDetails'

const { optionalDetailsFrom } = RegisterContent

const EXCLUDED_COUNTRIES = [
  'Russia',
  'Russian Federation',
  'Belarus',
  'North Korea',
  'Iran',
  'Islamic Republic of Iran',
  'Libya',
  'Somalia',
  'Syrian Arab Republic',
]

countries.registerLocale(english)

const countriesArray = Object.values(
  countries.getNames('en', { select: 'official' })
)

const COUNTRY_OPTIONS = countriesArray
  .filter((country: string) => !EXCLUDED_COUNTRIES.includes(country))
  .map((country: string) => ({
    label: country,
    value: country,
  }))

const EDUCATION_OPTIONS = optionalDetailsFrom.education.options.map(
  (option: string) => ({
    label: option,
    value: option,
  })
)

const EXPERIENCE_OPTIONS = optionalDetailsFrom.experience.options.map(
  (option: string) => ({
    label: option,
    value: option,
  })
)

const REFERRAL_OPTIONS = optionalDetailsFrom.referralSource.options.map(
  (option: string) => ({
    label: option,
    value: option,
  })
)

const FRAMEWORK_OPTIONS = optionalDetailsFrom.framework.options.map(
  (option: string) => ({
    label: option,
    value: option,
  })
)

const ATTEND_QHACK_OPTIONS = optionalDetailsFrom.qhack.options.map(
  (option: string) => ({
    label: option,
    value: option,
  })
)

const OptionalDetailsForm = ({
  goToPreviousStep,
  onUpdateAnswers,
  userInformation,
}: {
  goToPreviousStep: () => void
  onUpdateAnswers: (selectedAnswers: OptionType) => void
  userInformation: UserInformationObject
}) => {
  const [selectedAnswers, setSelectedAnswers] = useState<OptionType>({
    country: '',
    education: '',
    experience: '',
    referralSource: [],
    framework: [],
    attendQHackBefore: '',
  })

  const DROPDOWN_LIST = [
    {
      title: optionalDetailsFrom.country.title,
      options: COUNTRY_OPTIONS,
      key: optionalDetailsFrom.country.placeholder,
      selectedValueKey: 'country',
      onChange: (newValue: IOption) =>
        setSelectedAnswers({ ...selectedAnswers, country: newValue.value }),
    },
    {
      title: optionalDetailsFrom.education.title,
      options: EDUCATION_OPTIONS,
      key: optionalDetailsFrom.education.placeholder,
      selectedValueKey: 'education',
      onChange: (newValue: IOption) =>
        setSelectedAnswers({ ...selectedAnswers, education: newValue.value }),
    },
    {
      title: optionalDetailsFrom.experience.title,
      options: EXPERIENCE_OPTIONS,
      key: optionalDetailsFrom.experience.placeholder,
      selectedValueKey: 'experience',
      onChange: (newValue: IOption) =>
        setSelectedAnswers({ ...selectedAnswers, experience: newValue.value }),
    },
    {
      title: optionalDetailsFrom.referralSource.title,
      options: REFERRAL_OPTIONS,
      key: optionalDetailsFrom.referralSource.placeholder,
      isMulti: true,
      selectedValueKey: 'referralSource',
      onChange: (newValue: IOption[]) => {
        setSelectedAnswers({
          ...selectedAnswers,
          referralSource: newValue,
        })
      },
    },
    {
      title: optionalDetailsFrom.framework.title,
      options: FRAMEWORK_OPTIONS,
      isMulti: true,
      key: optionalDetailsFrom.framework.placeholder,
      selectedValueKey: 'framework',
      onChange: (newValue: IOption[]) =>
        setSelectedAnswers({ ...selectedAnswers, framework: newValue }),
    },
    {
      title: optionalDetailsFrom.qhack.title,
      options: ATTEND_QHACK_OPTIONS,
      key: optionalDetailsFrom.qhack.placeholder,
      selectedValueKey: 'attendQHackBefore',
      onChange: (newValue: IOption) =>
        setSelectedAnswers({
          ...selectedAnswers,
          attendQHackBefore: newValue.value,
        }),
    },
  ]

  useEffect(() => {
    if (userInformation) {
      setSelectedAnswers({
        country: userInformation.country,
        education: userInformation.education,
        experience: userInformation.experience,
        referralSource: userInformation.referralSource,
        framework: userInformation.framework,
        attendQHackBefore: userInformation.attendQHackBefore,
      })
    }
  }, [userInformation])

  return (
    <form
      name={FORM_NAME}
      onSubmit={(e) => {
        e.preventDefault()
        onUpdateAnswers(selectedAnswers)
      }}
      data-testid="optional-register-form"
      autoComplete="off"
    >
      <p className="text-sm text-gray-1 mb-4 text-center">
        {optionalDetailsFrom.text}
      </p>
      <div
        className="mb-4 text-sm"
        onKeyDown={(e: React.KeyboardEvent<HTMLElement>) => {
          e.key === 'Enter' && e.preventDefault()
        }}
      >
        {DROPDOWN_LIST.map((dropdown, index) => (
          <div className="mt-6" key={index}>
            <label
              className="mb-2 block text-qhack-2024-navy-1 font-sora text-sm"
              htmlFor={`react-select-${index + 2}-input`}
            >
              {dropdown.title}
            </label>
            <Select
              isMulti={dropdown.isMulti ? true : false}
              className="w-full"
              fieldLabel={dropdown.key}
              fieldLabelHidden
              fieldName={dropdown.selectedValueKey}
              formName={FORM_NAME}
              placeholder={dropdown.key}
              onChange={dropdown.onChange}
              options={dropdown.options}
              closeMenuOnSelect={dropdown.isMulti ? false : true}
              blurInputOnSelect={dropdown.isMulti ? false : true}
              value={
                selectedAnswers[dropdown.selectedValueKey]
                  ? Array.isArray(selectedAnswers[dropdown.selectedValueKey])
                    ? selectedAnswers[dropdown.selectedValueKey]
                    : {
                        label: `${selectedAnswers[dropdown.selectedValueKey]}`,
                        value: selectedAnswers[dropdown.selectedValueKey],
                      }
                  : ''
              }
            />
          </div>
        ))}
      </div>
      <div className="mt-8">
        <button
          type="submit"
          className="Button__Navy--primary navigationButton"
        >
          {optionalDetailsFrom.nextButtonLabel}
        </button>
        <button
          type="button"
          onClick={() => goToPreviousStep()}
          className="Button__Grey navigationButton"
        >
          {optionalDetailsFrom.backButtonLabel}
        </button>
      </div>
    </form>
  )
}

export default OptionalDetailsForm
