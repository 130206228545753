const ENVIRONMENT_URLS = {
  LOCAL: {
    SITE_URL: 'http://localhost:3001',
    API_URL: `https://dev.cloud.pennylane.ai`,
  },
  DEV: {
    SITE_URL: 'https://dev.qhack.ai',
    API_URL: `https://dev.cloud.pennylane.ai`,
  },
  STAGING: {
    SITE_URL: 'https://staging.qhack.ai',
    API_URL: `https://staging.cloud.pennylane.ai`,
  },
  PROD: {
    SITE_URL: 'https://qhack.ai',
    API_URL: `https://cloud.pennylane.ai`,
  },
}

const ENVIRONMENT_CONFIG = {
  LOCAL: {
    activeEventSlug: 'demo-event',
    siteURL: 'http://localhost:3001/',
    dashboardOrigin: 'http://localhost:3000',
    auth0: {
      enabled: true,
      domain: 'auth.dev.cloud.pennylane.ai',
      clientId: '8BZsYkBm1sosGoG0pdpAaQCPI3B1HJaF',
      audience: ENVIRONMENT_URLS.LOCAL.API_URL,
    },
    graphql: {
      challengeSystem: `http://localhost:8003/graphql`,
      profileService: {
        profileSetup: `${ENVIRONMENT_URLS.LOCAL.API_URL}/profiles/onboard/graphql`,
        ProfileUpdate: `${ENVIRONMENT_URLS.LOCAL.API_URL}/profiles/member/graphql`,
      },
    },
    newsletterMailchimp: {
      baseURL: `https://xanadu.us17.list-manage.com/subscribe/post?u=725f07a1d1a4337416c3129fd&amp;id=`,
      mailingList: `294b062630`,
    },
    welcomeEmailMailchimp: {
      baseURL: `https://xanadu.us17.list-manage.com/subscribe/post?u=725f07a1d1a4337416c3129fd&amp;id=`,
      mailingList: `b6d4779b78`,
    },
  },
  DEV: {
    activeEventSlug: '',
    siteURL: 'https://dev.qhack.ai/',
    dashboardOrigin: ENVIRONMENT_URLS.DEV.SITE_URL,
    auth0: {
      enabled: true,
      domain: 'auth.dev.cloud.pennylane.ai',
      clientId: '8BZsYkBm1sosGoG0pdpAaQCPI3B1HJaF',
      audience: ENVIRONMENT_URLS.DEV.API_URL,
    },
    graphql: {
      challengeSystem: `${ENVIRONMENT_URLS.DEV.API_URL}/standalone/challenge-system/graphql`,
      profileService: {
        profileSetup: `${ENVIRONMENT_URLS.DEV.API_URL}/profiles/onboard/graphql`,
        ProfileUpdate: `${ENVIRONMENT_URLS.DEV.API_URL}/profiles/member/graphql`,
      },
    },
    newsletterMailchimp: {
      baseURL: `https://xanadu.us17.list-manage.com/subscribe/post?u=725f07a1d1a4337416c3129fd&amp;id=`,
      mailingList: `294b062630`,
    },
    welcomeEmailMailchimp: {
      baseURL: `https://xanadu.us17.list-manage.com/subscribe/post?u=725f07a1d1a4337416c3129fd&amp;id=`,
      mailingList: `b6d4779b78`,
    },
  },
  STAGING: {
    activeEventSlug: '',
    siteURL: 'https://staging.qhack.ai/',
    dashboardOrigin: ENVIRONMENT_URLS.STAGING.SITE_URL,
    auth0: {
      enabled: true,
      domain: 'auth.staging.cloud.pennylane.ai',
      clientId: 'ckfpK2PScCLcVQIeLn9LM44zq1PUzkAS',
      audience: ENVIRONMENT_URLS.STAGING.API_URL,
    },
    graphql: {
      challengeSystem: `${ENVIRONMENT_URLS.STAGING.API_URL}/qhack-2024/challenge-system/graphql`,
      profileService: {
        profileSetup: `${ENVIRONMENT_URLS.STAGING.API_URL}/profiles/onboard/graphql`,
        ProfileUpdate: `${ENVIRONMENT_URLS.STAGING.API_URL}/profiles/member/graphql`,
      },
    },
    newsletterMailchimp: {
      baseURL: `https://xanadu.us17.list-manage.com/subscribe/post?u=725f07a1d1a4337416c3129fd&amp;id=`,
      mailingList: `294b062630`,
    },
    welcomeEmailMailchimp: {
      baseURL: `https://xanadu.us17.list-manage.com/subscribe/post?u=725f07a1d1a4337416c3129fd&amp;id=`,
      mailingList: `b6d4779b78`,
    },
  },
  PROD: {
    activeEventSlug: '',
    siteURL: 'https://qhack.ai/',
    dashboardOrigin: ENVIRONMENT_URLS.PROD.SITE_URL,
    auth0: {
      enabled: true,
      domain: 'auth.cloud.pennylane.ai',
      clientId: 'SXdkhNsiLT0GdrOTFAR2gJ5tq8oGVcg3',
      audience: ENVIRONMENT_URLS.PROD.API_URL,
    },
    graphql: {
      challengeSystem: `${ENVIRONMENT_URLS.PROD.API_URL}/qhack-2024/challenge-system/graphql`,
      profileService: {
        profileSetup: `${ENVIRONMENT_URLS.PROD.API_URL}/profiles/onboard/graphql`,
        ProfileUpdate: `${ENVIRONMENT_URLS.PROD.API_URL}/profiles/member/graphql`,
      },
    },
    newsletterMailchimp: {
      baseURL: `https://xanadu.us17.list-manage.com/subscribe/post?u=725f07a1d1a4337416c3129fd&amp;id=`,
      mailingList: `294b062630`,
    },
    welcomeEmailMailchimp: {
      baseURL: `https://xanadu.us17.list-manage.com/subscribe/post?u=725f07a1d1a4337416c3129fd&amp;id=`,
      mailingList: `b6d4779b78`,
    },
  },
}

export const getCurrentEnv = () => {
  const ORIGIN_URL = typeof window !== 'undefined' ? `${window.origin}` : ''
  const currentEnv =
    process.env.DEV ||
    (ORIGIN_URL.length > 0 &&
      ENVIRONMENT_CONFIG.DEV.siteURL.includes(ORIGIN_URL))
      ? 'DEV'
      : process.env.STAGING ||
        (ORIGIN_URL.length > 0 &&
          ENVIRONMENT_CONFIG.STAGING.siteURL.includes(ORIGIN_URL))
      ? 'STAGING'
      : process.env.PROD ||
        (ORIGIN_URL.length > 0 &&
          ENVIRONMENT_CONFIG.PROD.siteURL.includes(ORIGIN_URL))
      ? 'PROD'
      : 'LOCAL'
  return currentEnv
}

export const getCurrentEnvConfig = () =>
  ENVIRONMENT_CONFIG[getCurrentEnv() as keyof typeof ENVIRONMENT_CONFIG]
