import React from 'react'
import { navigate } from 'gatsby'
import { Auth0Provider, AppState } from '@auth0/auth0-react'
import { getCurrentEnvConfig } from '../../../config'
import { usePathPrefix } from '../../hooks/usePathPrefix'

const auth0Config = getCurrentEnvConfig().auth0

export const onRedirectCallback = (appState?: AppState) => {
  // Use Gatsby's navigate method to replace the url
  navigate(appState?.returnTo || '/', { replace: true })
}

const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const pathPrefix: string = usePathPrefix()
  return auth0Config.enabled ? (
    <Auth0Provider
      domain={auth0Config.domain}
      clientId={auth0Config.clientId}
      authorizationParams={{
        audience: auth0Config.audience,
        redirect_uri:
          typeof window !== 'undefined'
            ? `${window.location.origin}${pathPrefix}/`
            : '',
      }}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens={true}
      useRefreshTokensFallback={true}
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  ) : (
    children
  )
}

export default AuthProvider
