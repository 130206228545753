import React, { useMemo } from 'react'
import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  from,
  ApolloProvider,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import fetch from 'isomorphic-fetch'
import { useAuth0 } from '@auth0/auth0-react'
import { getCurrentEnvConfig } from '../../../config'

const config = getCurrentEnvConfig()

const ApolloClientProfileUpdate = ({ children }: { children: JSX.Element }) => {
  const { getAccessTokenSilently, loginWithRedirect, isAuthenticated } =
    useAuth0()

  const client = useMemo(() => {
    const httpLinkProfileUpdate = new HttpLink({
      uri: config.graphql.profileService.ProfileUpdate,
      fetch,
    })

    const authLink = setContext(async (_, { headers }) => {
      let token = ''
      if (isAuthenticated)
        try {
          token = await getAccessTokenSilently({
            authorizationParams: {
              audience: config.auth0.audience,
            },
          })
        } catch (e) {
          const errorMessage = (e as { error: string }).error || ''
          if (
            errorMessage === 'missing_refresh_token' ||
            errorMessage === 'invalid_grant'
          ) {
            loginWithRedirect()
          }
          console.error(e)
          token = ''
        }

      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : '',
        },
      }
    })

    return new ApolloClient({
      link: from([authLink, httpLinkProfileUpdate]),
      cache: new InMemoryCache(),
    })
  }, [])

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export default ApolloClientProfileUpdate
