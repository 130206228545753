/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Dispatch, SetStateAction } from 'react'
import { createContext, useState } from 'react'

interface IEvent {
  id: string
  slug: string
  status: string
  registrationOpen: boolean | null
  teamsLocked: boolean | null
}

interface IPlayer {
  id: string
  handle: string
}

interface IAppContext {
  eventDetails: IEvent
  setEventDetails: (args: IEvent) => void
  playerDetails: IPlayer
  setPlayerDetails: (args: IPlayer) => void
  showRegisterModal: boolean
  setShowRegisterModal: Dispatch<SetStateAction<boolean>>
}

export const AppContext = createContext<IAppContext>({
  eventDetails: {
    id: '',
    slug: '',
    status: '',
    registrationOpen: false,
    teamsLocked: true,
  },
  setEventDetails: (args: IEvent) => {},
  playerDetails: {
    id: '',
    handle: '',
  },
  setPlayerDetails: (args: IPlayer) => {},
  showRegisterModal: false,
  setShowRegisterModal: () => null,
})

export const AppProvider = ({ children }: { children: JSX.Element }) => {
  const [eventDetails, setEventDetails] = useState<IEvent>({
    id: '',
    slug: '',
    status: '',
    registrationOpen: false,
    teamsLocked: true,
  })
  const [playerDetails, setPlayerDetails] = useState<IPlayer>({
    id: '',
    handle: '',
  })
  const [showRegisterModal, setShowRegisterModal] = useState(false)

  return (
    <AppContext.Provider
      value={{
        eventDetails,
        setEventDetails,
        playerDetails,
        setPlayerDetails,
        showRegisterModal,
        setShowRegisterModal,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

const RootElement = ({ children }: { children: JSX.Element }) => {
  return <AppProvider>{children}</AppProvider>
}

export default RootElement
