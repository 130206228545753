import * as React from 'react'
import { Footer, Banner } from 'shared-components'
import AuthProvider from '../../providers/AuthProvider/AuthProvider'
import ApolloClientProfileUpdate from '../../providers/ApolloProvider/ApolloClientProfileUpdate'
import NavBarWrapper from '../NavbarWrapper/NavbarWrapper'
import LinkComponent from '../LinkComponent/LinkComponent'
import 'boxicons/css/boxicons.min.css'

export const Layout = ({ children }: { children: React.ReactNode }) => {
  return (
    <AuthProvider>
      <>
        <ApolloClientProfileUpdate>
          <NavBarWrapper />
        </ApolloClientProfileUpdate>
        <Banner />
        {children}
        <Footer LinkComponent={LinkComponent} />
      </>
    </AuthProvider>
  )
}

export default Layout
