import React from 'react'
import { useState, useEffect, useContext } from 'react'
import { Navbar } from 'shared-components'
import { useAuth0 } from '@auth0/auth0-react'
import LinkComponent from '../LinkComponent/LinkComponent'
import Register from '../Register/Register'
import { gql, useLazyQuery } from '@apollo/client'
import ApolloClientChallengeSystem from '../../providers/ApolloProvider/ApolloClientChallengeSystem'
import { AppContext } from '../../providers/AppContext/AppContext'
import { get } from 'lodash'
import { getCurrentEnvConfig } from '../../../config'

export const GET_PROFILE_DETAILS = gql(`
    query getProfile($userId: UserID!){
        profile(userId: $userId) {
            handle
            id
        }
    }
`)

const NavBarWrapper = () => {
  const { playerDetails, setPlayerDetails } = useContext(AppContext)
  const { isAuthenticated, loginWithRedirect, logout, user } = useAuth0()
  const [userId, setUserId] = useState('')

  const [
    getUserProfile,
    { data: userProfileResponse, error: userProfileError },
  ] = useLazyQuery(GET_PROFILE_DETAILS, {
    variables: { userId: userId },
  })

  useEffect(() => {
    if (user && user.sub) {
      setUserId(user.sub)
    }
  }, [user])

  useEffect(() => {
    if (userId && !playerDetails.handle) {
      getUserProfile()
    }
  }, [userId])

  useEffect(() => {
    if (userProfileResponse) {
      setPlayerDetails({
        ...playerDetails,
        handle: get(userProfileResponse, 'profile.handle', null),
      })
    }
  }, [userProfileResponse, userProfileError])

  return (
    <Navbar
      LinkComponent={LinkComponent}
      isAuthenticated={isAuthenticated}
      logIn={() => {
        loginWithRedirect()
      }}
      signOut={() => {
        logout({
          logoutParams: {
            returnTo:
              typeof window !== 'undefined' ? `${window.location.origin}` : '',
          },
        })
      }}
      handle={playerDetails.handle ? `@${playerDetails.handle}` : 'Loading...'}
      RegisterComponent={
        <ApolloClientChallengeSystem>
          <Register />
        </ApolloClientChallengeSystem>
      }
      hasActiveEvent={getCurrentEnvConfig().activeEventSlug}
    />
  )
}

export default NavBarWrapper
