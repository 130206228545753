export default {
  title: `Register for QHack 2024`,
  registerButtonLabel: {
    loggedIn: 'Register now',
    loggedOut: 'Sign in to register',
  },
  registeredUserCTA: {
    eventNotStarted: {
      label: `Get ready for challenges`,
      link: `/get-ready`,
    },
    eventStarted: {
      label: `Challenge portal`,
      link: `/dashboard/team`,
    },
  },
  userDetailsForm: {
    helpText: `You may change your username in your account settings on pennylane.ai. For additional support contact registration@qhack.ai.`,
    referText: `If a user referred you to QHack, please enter their username. You may only enter one referrer.`,
    tooltipLabel: `Referral program info`,
    tooltipText: `The top 10 referrers will receive an exclusive swag pack. Be sure to tell your friends and get them to register and enter your username!`,
    nextButtonLabel: 'Next',
    cancelButtonLabel: 'Cancel',
    invalidUsernameError:
      'Username must be between 3-20 characters, and can only contain alphanumeric characters and underscores.',
  },
  optionalDetailsFrom: {
    text: `Tell us a bit about your background - this information helps us improve our events! All information is optional.`,
    country: {
      placeholder: 'Select country',
      title: 'What country will you be participating from?',
      // Country options are automatically populated
    },
    education: {
      placeholder: 'Select education level',
      title: 'What is your highest level of education?',
      options: ['High School', 'Undergraduate', 'Masters', 'PhD', 'Other'],
    },
    experience: {
      placeholder: 'Select experience',
      title: 'What is your experience with quantum computing?',
      options: [
        'None',
        'I have a bit of experience',
        `I'm a quantum computing enthusiast!`,
        'I work with quantum every day/am employed in the industry',
      ],
    },
    referralSource: {
      placeholder: 'Select source',
      title: 'How did you hear about this event?',
      options: [
        'Xanadu Newsletter',
        'PennyLane website',
        'Twitter',
        'LinkedIn',
        'Google',
        'Discord',
        'Slack',
        'Word of mouth',
        'Other',
      ],
    },
    framework: {
      placeholder: 'Select framework',
      title:
        'Which of the following quantum programming frameworks have you used before?',
      options: [
        'PennyLane',
        'Qiskit',
        'Cirq',
        'cuQuantum',
        'CUDA Quantum',
        't|ket>',
        'QuTip',
        'Other',
      ],
    },
    qhack: {
      placeholder: 'Select option',
      title: 'Have you attended past QHacks?',
      options: ['Yes', 'No'],
    },
    nextButtonLabel: 'Next',
    backButtonLabel: 'Back',
  },
  termsAcceptForm: {
    codeOfConduct: `I agree to the [Code of Conduct](/code-of-conduct)*`,
    terms: `I agree to the [Terms & Conditions](/terms-and-conditions)*`,
    newsletterSubscribe: `I want to subscribe to the monthly Xanadu newsletter`,
    registerButtonLabel: 'Complete registration',
    backButtonLabel: `Back`,
  },
  registrationSuccess: {
    title: `Thank you for registering for QHack 2024!`,
    buttonLabel: `Back to QHack.ai`,
  },
}
